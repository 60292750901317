import { phoneToStringV1 } from '@mtntop/utils/src/components/PhoneField/utils/filters';
import { partUnitPrice } from '@/plugins/cartItemsMixin';
import { pushDataLayerEvent } from '@/lib/dataLayer';
import dataLayerHelpers from '../components/filters/data.layer.list.mixin.js';

export const initStoreCustomer = (customerId, store) => {
  try {
    if (customerId?.email) {
      const phone_number =
        (customerId.CustomerPhoneNumbers?.length && phoneToStringV1(customerId.CustomerPhoneNumbers[0])) || '';
      pushDataLayerEvent({
        event_name: 'user_data',
        email: customerId?.email,
        ...(phone_number && { phone_number }),
        address: {
          first_name: customerId.firstName || '',
          last_name: customerId.lastName || '',
          ...((customerId.CustomerAddresses?.length && {
            street: (customerId.CustomerAddresses[0].street1 || '').trim(),
            city: (customerId.CustomerAddresses[0].city || '').trim(),
            region: (customerId.CustomerAddresses[0].state || '').trim(),
            postal_code: (customerId.CustomerAddresses[0].zip || '').trim(),
            country: (customerId.CustomerAddresses[0].country || '').trim(),
          }) || {
            street: (customerId.street1 || '').trim(),
            city: (customerId.city || '').trim(),
            region: (customerId.state || '').trim(),
            postal_code: (customerId.zip || '').trim(),
            country: (customerId.country || '').trim(),
          }),
        },
      }, store);
    }
  } catch (e) {
    console.log(e);
  }
};
export default {
  mixins: [dataLayerHelpers],
  methods: {
    onDataLaterSetUserData(customerId) {
      const store = this.$store.getters.storeInformation;
      if (store?.gtmKey) {
        pushDataLayerEvent(['config', store?.gtmKey, { allow_enhanced_conversions: true }], this.storeInformation);
      }
      initStoreCustomer(customerId, store);
    },
    onDataLayerLogin(customer, method = 'email') {
      const data = {
        method,
        user_id: customer._id,
      };
      this.onDataLayerPush('login', data);
    },
    onDataLayerSignUp(customer, method = 'email') {
      const data = {
        method,
        user_id: customer?._id,
        email: customer?.email,
      };
      this.onDataLayerPush('sign_up', data);
    },
    onDataLayerSearch(search_term) {
      const data = {
        search_term,
      };
      this.onDataLayerPush('search', data);
    },
    onDataLayerShare(method, content_type, content_id) {
      const data = {
        method,
        content_type,
        content_id,
      };
      this.onDataLayerPush('share', data);
    },
    onDataLayerAddToWishlist(productItem) {
      const data = {
        items: [productItem],
      };
      this.onDataLayerPush('add_to_wishlist', data);
    },
    onDataLayerViewCart(orders, store, cartType) {
      try {
        const data = {
          ecommerce: {
            items: orders.map((order) => ({
              item_id: order.productId,
              item_name: order.productName,
              affiliation: store?.storeName,
              currency: store?.currency?.code || 'USD',
              discount: order.productDiscount,
              index: 0,
              item_brand: order.parts[0]?.partColor || '',
              item_list_id: order.parts[0]?.productId || order.productSlug,
              item_list_name:
                order.parts[0]?.productName ||
                (order.productSlug || '')
                  .split('_')
                  .map((e) => e.toCapitalize())
                  .join(' ') ||
                '',
              ...partUnitPrice(order, order.LineItemArray[0].PartArray[0], 0, true),
              quantity: order.quantity,
            })),
          },
        };
        const normalizeCartType = cartType?.toLowerCase();
        const eventName = normalizeCartType !== 'cart' ? `view_${normalizeCartType}_cart` : 'view_sample_cart';
        this.onDataLayerPush(eventName, data);
      } catch (e) {}
    },

    onDataLayerPush(event, data) {
      const eventData = {
        ...data,
        event,
        timestamp: Date.now(),
      };
      pushDataLayerEvent(eventData, this.storeInformation);
    },
    onDataLayerCheckoutBegin(cartType) {
      try {
        const customer = this.$store.getters['auth/loggedInUser'];
        const normalizeCartType = cartType?.toLowerCase();
        const eventName = !['cart', 'configured'].includes(normalizeCartType) ? `begin_${normalizeCartType}_checkout` : 'begin_sample_checkout';
        pushDataLayerEvent({
          event: eventName,
          ...(customer?.email && { email: customer.email }),
          ecommerce: {
            ...(customer?.email && { email: customer.email }),
            items: this.ordersByCartType(this.cartType).map((order, index) => {
              const item = order.LineItemArray[0]?.localProduct || {};
              const brandGroup = (item.facetGroups || []).find((e) => e.slug === 'brand');
              const brand =
                item.facets.find((e) => e.facetGroup?.slug === 'brand' || e.facetGroup === brandGroup?._id)?.name ||
                item.supplierId?.name;

              return {
                item_id: order.productId,
                item_name: order.productName,
                affiliation: this.$store.getters.storeInformation.storeName,
                currency: 'USD',
                discount: order.productDiscount,
                index,
                ...(brand && { item_brand: brand }),
                item_list_id: order.parts[0]?.productId || order.productSlug,
                item_list_name:
                  order.parts[0]?.productName ||
                  order.productSlug
                    .split('_')
                    .map((e) => e.toCapitalize())
                    .join(' '),
                ...partUnitPrice(order, order.LineItemArray[0]?.PartArray[0], 0, true),
                quantity: order.quantity,
              };
            }),
          },
        }, this.storeInformation);
        this.onDataLaterSetUserData(customer);
      } catch (e) {
        console.log(e);
      }
    },
    onDataLayerCheckout(order, cartType) {
      try {
        const customer = order.customerId;
        const normalizeCartType = cartType?.toLowerCase();
        const eventName = !['cart', 'configured'].includes(normalizeCartType) ? `${normalizeCartType}_checkout` : 'sample_checkout';
        pushDataLayerEvent({
          event: eventName,
          ...(customer?.email && { email: customer.email }),
          ecommerce: {
            ...(customer?.email && { email: customer.email }),
            transaction_id: this.order.orderId,
            affiliation: this.$store.getters.storeInformation.storeName,
            value: this.orderTotal.roundCeil(),
            tax: this.taxTotal.roundCeil(),
            shipping: this.shippingTotal.roundCeil(),
            currency: this.$store.getters.storeCurrencyCode,
            ...(this.discounts.length && { coupon: this.discounts[0] }),

            items: this.ordersByCartType(this.cartType).map((order, index) => {
              const item = order.LineItemArray[0]?.localProduct || {};
              const brandGroup = (item.facetGroups || []).find((e) => e.slug === 'brand');
              const brand =
                item.facets.find((e) => e.facetGroup?.slug === 'brand' || e.facetGroup === brandGroup?._id)?.name ||
                item.supplierId?.name;

              return {
                item_id: order.productId,
                item_name: order.productName,
                affiliation: this.$store.getters.storeInformation.storeName,
                currency: this.$store.getters.storeCurrencyCode,
                discount: order.productDiscount,
                index,
                ...(brand && { item_brand: brand }),
                item_list_id: order.parts[0]?.productId || order.productSlug,
                item_list_name:
                  order.parts[0]?.productName ||
                  order.productSlug
                    .split('-')
                    .map((e) => e.toCapitalize())
                    .join(' '),
                ...partUnitPrice(order, order.LineItemArray[0]?.PartArray[0], 0, true),
                quantity: order.quantity,
              };
            }),
          },
        }, this.storeInformation);
        this.onDataLaterSetUserData(order.customerId);
      } catch (e) {
        console.log(e);
      }
    },
    onDataLaterAddToCart(reqBody, event = 'add_to_cart') {
      try {
        const item = this.productData;
        item.categoriesUsed = (this.productCategories || []).map((e) => e.name);
        const customer = reqBody.order?.email ? reqBody.order : this.$store.getters['auth/loggedInUser'];
        pushDataLayerEvent({
          event: ['cart', 'configured'].includes(event.toLowerCase()) ? 'add_to_sample_cart' : `add_to_${event}_cart`,
          ...(customer?.email && { email: customer.email }),
          ecommerce: {
            ...(customer?.email && { email: customer.email }),
            items: reqBody.order.parts.map((part, index) => ({
              ...this.productItemDataLayer(this.productData),
              index,
              item_id: part.partId,
              item_list_id: `add-to-cart-${this.productData.slug}`,
              item_list_name: `add to cart ${this.productData.slug
                .split('_')
                .map((e) => e.toCapitalize())
                .join(' ')}`,
              price: part.unitPrice.roundCeil(),
              discount: part.productDiscount,
              quantity: parseInt(part.quantity),
              variant: part.colors.map((e) => e.colorName || e.name)[0] || '',
            })),
          },
        }, this.storeInformation);
        this.onDataLaterSetUserData(customer);
      } catch (e) {
        console.log(e);
      }
    },
    onDataLayerViewItem(event = 'view_item') {
      try {
        if (this.productData.status !== 'active' || !this.productData.analyticsParts?.[0]) return;
        const item = this.productData.analyticsParts[0];
        item.categoriesUsed = this.productCategories.map((e) => e.name);

        const totalPrice = this.totalPrice || this.$refs.form?.totalPrice;
        pushDataLayerEvent({
          event,
          ecommerce: {
            items: [
              {
                ...this.productItemDataLayer({
                  ...this.productData,
                  ...item,
                }),
                item_id: this.productData.productId,
                item_list_id: `product-view-${this.productData.slug}`,
                item_list_name: `product view ${this.productData.slug
                  .split('_')
                  .map((e) => e.toCapitalize())
                  .join(' ')}`,
                price: totalPrice?.perEach ? totalPrice?.perEach.roundCeil() : null,
                quantity: (this.orderData && parseFloat(this.orderData.quantity)) || this.quantity || 1,
                discount: totalPrice?.productDiscount,
                variant: item.colorName,
              },
            ],
          },
        }, this.storeInformation);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
