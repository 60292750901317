/**
 * round depend from 3 digit after coma, 0.011 -> result is 0.02. Same as Math.ceil just for 3th decimal
 * (0.01132).toFixed(2) -> 0.01
 * (0.01132).roundCeil(2) -> 0.02
 * Note any change here require updates on feeds, Math working differently on nodejs and browser
 * */
const roundCeil = function(range = 2) {
  try {
    const str = this.toString();
    if (isNaN(parseFloat(str))) return this;
    const v = parseFloat(this.toFixed(Math.min(str.length, 10))).toString();
    const dec = v.split('.');
    let res = parseFloat(v);
    if (dec[1]) {
      res = parseFloat(`${dec[0]}.${dec[1].substr(0, range)}`);
      if (parseInt(dec[1].substr(range, 1)) > 0) {
        return parseFloat((res + 1 / Math.pow(10, range)).toFixed(range));
      }
    }
    return res;
  } catch (e) {
    return this;
  }
};

const roundToNearest = function() {
  try {
    const value = parseFloat(this);
    if (isNaN(value)) return this;

    const epsilon = 1e-6;

    if (Math.abs(value) < epsilon) return 0;

    const valueStr = value.toFixed(10);
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex === -1) return parseFloat(valueStr);

    const decimals = valueStr.slice(decimalIndex + 1);

    // Stripe rule: Round up if third decimal place is 5 or more, otherwise round down
    let needsRoundingUp = false;
    if (decimals.length >= 3) {
      const thirdDigit = parseInt(decimals[2], 10);
      needsRoundingUp = thirdDigit >= 5;
    }

    const roundedValue = needsRoundingUp ? Math.ceil(value * 100) / 100 : Math.floor(value * 100) / 100;

    return roundedValue % 1 === 0 ? parseInt(roundedValue) : parseFloat(roundedValue.toFixed(2));
  } catch (e) {
    return this;
  }
};

const toCapitalize = function() {
  return this.toLowerCase().replace(/^.|\s\S/g, function(a) {
    return a.toUpperCase();
  });
};

const toCamelCase = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const enumToNormal = function() {
  return this.replace(/_/g, ' ').toCapitalize();
};

Number.prototype.roundCeil = roundCeil;
String.prototype.roundCeil = roundCeil;
String.prototype.roundToNearest = roundToNearest;
Number.prototype.roundToNearest = roundToNearest;
String.prototype.toCapitalize = toCapitalize;
String.prototype.enumToNormal = enumToNormal;
String.prototype.toCamelCase = toCamelCase;
setTimeout(() => {
  Number.prototype.roundCeil = roundCeil;
  String.prototype.roundCeil = roundCeil;
  String.prototype.roundToNearest = roundToNearest;
  Number.prototype.roundToNearest = roundToNearest;
  String.prototype.toCapitalize = toCapitalize;
  String.prototype.enumToNormal = enumToNormal;
  String.prototype.toCamelCase = toCamelCase;
  //TODO; bit dev not working, need to rewrite
}, 1000);
String.prototype.toCapitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
try {
  if (!Object.prototype.hasOwnProperty('asJson')) {
    Object.defineProperty(Object.prototype, 'asJson', {
      value: function() {
        return JSON.parse(JSON.stringify(this));
      },
      configurable: true,
      writable: true,
    });
  }
} catch (e) {}
