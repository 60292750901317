import { HTTP } from './../middleware/seo';

export async function pushDataLayerEvent(layer, store, skipClear = false) {
  try {
    const dataLayer = window.dataLayer;
    if (!skipClear && !!layer.ecommerce) {
      dataLayer?.push({ ecommerce: null });
    }
    dataLayer?.push(layer);

    const event = layer.event || layer.event_name;
    const skipViewEvents = ['view_item_list', 'view_item'];
    if (event && store && !skipViewEvents.includes(event)) {
      await HTTP.post(`/stores/${store?._id || store}/events`, {
        event,
        payload: JSON.stringify(layer || {})
      });
    }
  } catch (e) {}
}