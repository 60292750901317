import { CLEAR, PENDING, LOADING, SUCCESS, CART_LOAD } from './constants';
import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';
import { partUnitPrice } from '@/plugins/cartItemsMixin';
import { pushDataLayerEvent } from '@/lib/dataLayer';

const Cookie = process.client ? require('js-cookie') : undefined;
const IS_BOT_SERVER = /apibot/.test(process.env.API_URL || '');

export const state = () => ({
  orders: [],
  notSavedBoxOrders: [],
  ordersLoading: false,
  cartState: PENDING, //
});
const loading = {};

export const getters = {
  orders: ({ orders }) => orders,
  cartState: ({ cartState }) => cartState,
  isCartSuccess: ({ cartState }) => cartState === SUCCESS,
  unsavedBoxItems: ({ notSavedBoxOrders }) => notSavedBoxOrders,
};
export const actions = {
  async clear({ commit }) {
    commit(CLEAR);
  },
  async loadOrders(
    { commit, state: { orders } },
    { orders: cookieOrders = [], storeInformation, customerId, onlyCount = false, forceLoad = false, addToExistsOrders }
  ) {
    const url = `/orders/customer/${customerId || undefined}`;
    let cartOrders = [];
    const { carts = [] } = storeInformation;
    const isMultipleCart = carts.length > 1;
    const needToLocadCarts = !orders.length || (!onlyCount && !orders.every((i) => i.orderId)) || forceLoad;
    if (needToLocadCarts && !IS_BOT_SERVER) {
      commit(CART_LOAD, LOADING);
      const params = { onlyCount, isMultipleCart, orders: cookieOrders };
      const requestKey = `${url}-${JSON.stringify(params)}`;
      loading[requestKey] = loading[requestKey] || this.$axios.get(url, { params, paramsSerializer });
      try {
        cartOrders = (await loading[requestKey]).data;
      } catch (e) {
      } finally {
        delete loading[requestKey];
      }
    }
    if (cartOrders.length) {
      if (addToExistsOrders) {
        commit('addOrders', cartOrders);
      } else {
        commit('setOrders', cartOrders);
      }
    }
    commit(CART_LOAD, SUCCESS);
  },

  async deleteAllOrders({ commit, state: { orders } }) {
    if (orders.length > 0) {
      const { _orderId, orderId } = orders[0];
      await this.$axios.delete(`/orders/${_orderId || orderId}`);
      Cookie.remove('cartOrders');
    }
    commit('setOrders', []);
  },
  async deleteOrder(state, { order, cartType }) {
    try {
      const {
        commit,
        state: { orders },
      } = state;
      const leftOrders = orders.filter((o) => o.poId !== order.poId);
      const res = await this.$axios.delete(`/purchaseOrders/${order.poId}`);
      leftOrders.forEach((e) => {
        e.order.updatedAt = res.data.updatedAt;
      });
      commit('setOrders', leftOrders);
      try {
        const normalizeCartType = cartType?.toLowerCase();
        pushDataLayerEvent({
          event: normalizeCartType === 'cart' ? 'remove_from_cart' : `remove_from_${normalizeCartType}_cart`,
          ecommerce: {
            items: [
              {
                item_id: order.productId,
                item_name: order.productName,
                affiliation: state.rootState.store.storeName,
                currency: state.rootState.store?.currency?.code || 'USD',
                discount: order.productDiscount,
                index: 0,
                item_brand: order.parts[0]?.partColor || '',
                item_list_id: order.parts[0]?.productId || order.productSlug,
                item_list_name:
                  order.parts[0]?.productName ||
                  order.productSlug
                    .split('_')
                    .map((e) => e.toCapitalize())
                    .join(' '),
                ...partUnitPrice(order, order.LineItemArray[0].PartArray[0], 0, true),
                quantity: order.quantity,
              },
            ],
          },
        }, order.storeId);
      } catch (e) {}
    } catch (e) {
      location.reload();
      // state.dispatch('loadOrders', {
      //   customerId: state.rootState.auth.user?._id,
      //   storeInformation: state.rootState.store,
      //   forceLoad: true,
      // });
    }
  },
  async updateOrder({ commit, state }, order) {
    const orders = state.orders;
    orders.forEach((e) => {
      if (e.order && e.order._id === order._id) e.order.updatedAt = order.updatedAt;
    });

    commit('setOrders', orders);
  },

  async updateOrderDetails({ commit, state }, order) {
    const orders = JSON.parse(JSON.stringify(state.orders));
    orders.forEach((e) => {
      if (e._id === order._id) {
        Object.assign(e, order);
      }
    });
    commit('setOrders', orders);
  },
};

export const mutations = {
  [CLEAR](state) {
    state.orders = [];
  },
  [CART_LOAD](state, cartState) {
    state.cartState = cartState;
  },
  addOrders(state, orders) {
    const newOrderList = _.uniqBy([...orders, ...state.orders], 'poId');
    newOrderList.forEach((e) => {
      orders.forEach(({ order }) => {
        if (e.order && e.order._id === order._id) e.order.updatedAt = order.updatedAt;
      });
    });
    state.orders = newOrderList;
  },
  setOrders(state, orders) {
    state.orders = orders;
  },
  addUnsavedBoxItem(state, order) {
    state.notSavedBoxOrders.push(order);
  },
  removeUnsavedBoxItem(state, index) {
    state.notSavedBoxOrders.splice(index, 1);
  },
  patchUnsavedBoxItem(state, { index, data }) {
    const box = state.notSavedBoxOrders[index];
    Object.assign(box, data);
  },
};
